import { Component } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NEW_DATE_FORMAT } from '@app/helpers/date.helper';

@Component({
    selector: 'devfu-dialog-sync-expiry',
    templateUrl: './dialog-sync-expiry.component.html',
    styleUrls: ['./dialog-sync-expiry.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: NEW_DATE_FORMAT }]
})
export class DialogSyncExpiryComponent {
    minVersionExpiryDate = new Date();
    newExpiry: Date|null = null;

    today = new Date();

    constructor() {
    }

    addHalfYear() {
        this.newExpiry = new Date(this.today);
        this.newExpiry.setMonth(this.today.getMonth() + 6);
    }

    addYears(count: number) {
        this.newExpiry = new Date(this.today);
        this.newExpiry.setFullYear(this.today.getFullYear() + count);
    }

}
