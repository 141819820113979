<h2 mat-dialog-title>Sync Expiry</h2>
<mat-dialog-content style="min-height: 140px">
    <p>Do you want to set final expiry date for the version?</p>
    <mat-form-field class="col-12 expiry-picker" appearance="outline">
        <mat-label>Expiry date</mat-label>
        <input [(ngModel)]="newExpiry" [matDatepicker]="picker" matInput>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <button (click)="newExpiry=null" *ngIf="newExpiry" aria-label="Clear" class="expiry-clear"
                mat-icon-button matSuffix>
            <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker #picker [startAt]="minVersionExpiryDate"></mat-datepicker>
    </mat-form-field>
    <div class="d-flex justify-content-between">
        <button mat-stroked-button (click)="addHalfYear()">6 months</button>
        <button mat-stroked-button (click)="addYears(1)">12 months</button>
        <button mat-stroked-button (click)="addYears(2)">2 years</button>
        <button mat-stroked-button (click)="addYears(3)">3 years</button>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="{expiry: newExpiry}">Yes</button>
    <button mat-button mat-dialog-close>No</button>
</mat-dialog-actions>
