import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {CoreService} from '@app/services/core.service';
import {DocumentSecurity} from '@app/models/document-security.model';
import {AuthService} from '@app/core';
import {joinPath} from '@app/helpers/path.helper';
import {Log} from '@app/helpers';

@Injectable()
export class DocumentSecurityService extends CoreService {
    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('document_security', auth);
        Log.c('DocumentSecurityService Ready...');
    }

    getPath(documentID: number, documentSecurityID = undefined) {
        return joinPath(this.baseEndpoint(), 'document', documentID, 'security', documentSecurityID);
    }

    getDocumentSecurity(documentID: number): Observable<DocumentSecurity[]> {
        return this.http.get<DocumentSecurity[]>(this.getPath(documentID), this.options);
    }

    getDocumentSecurityAudit(documentID: number): Observable<DocumentSecurity[]> {
        return this.http.get<DocumentSecurity[]>(this.getPath(documentID, 'audit'), this.options);
    }

    createDocumentSecurity(documentSecurity: DocumentSecurity): Observable<DocumentSecurity> {
        return this.http.post<DocumentSecurity>(this.getPath(documentSecurity.document_id), JSON.stringify(documentSecurity), this.options);
    }

    updateDocumentSecurity(documentSecurity: DocumentSecurity): Observable<DocumentSecurity> {
        return this.http.put<DocumentSecurity>(this.getPath(documentSecurity.document_id, documentSecurity.id), this.options);
    }

    deleteDocumentSecurity(documentID: number, documentSecurityID: number): Observable<any> {
        return this.http.delete<any>(this.getPath(documentID, documentSecurityID), this.options);
    }
}
